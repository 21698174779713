import { MutationTree, GetterTree } from 'vuex';
import { State, ClickEventName, ClickEventData } from '@root/modules/analytics/types/analytics';

type Payload = Record<string, { eventName: ClickEventName; eventData?: ClickEventData }>;

export const state: () => State = () => ({
  tracker: {
    edTracker: {
      clickEventName: null,
      clickEventData: null,
    },
    cXense: {
      clickEventName: null,
      clickEventData: null,
    },
    facebookPixel: {
      clickEventName: null,
      clickEventData: null,
    },
    getSiteControl: {
      clickEventName: null,
      clickEventData: null,
    },
    googleTagManager: {
      clickEventName: null,
      clickEventData: null,
    },
  },
  arrayTracker: {
    cXenseFunnel: [],
    cXensePixel: [],
  },
  showConsentSettings: false,
  vendorsList: null,
  fetchSSRCookies: true,
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  getVendorsList: (state) => state.vendorsList,
  getConsentSettingsVisibility: (state) => state.showConsentSettings,
};

export const mutations: MutationTree<State> = {
  setClickEvent(state, payload: Payload) {
    Object.keys(payload).forEach((trackerType) => {
      state.tracker[trackerType] = {
        clickEventName: payload[trackerType].eventName,
        clickEventData: payload[trackerType].eventData || null,
      };
    });
  },
  /* Although similar to the setClickEvent mutation, pushTrackingEvent uses arrayTracker, and its implementation differs */
  pushTrackingEvent(state, payload: Payload) {
    Object.keys(payload).forEach((trackerType) => {
      state.arrayTracker[trackerType].push({
        eventName: payload[trackerType].eventName,
        eventData: payload[trackerType].eventData || null,
      });
    });
  },
  setConsentSettingsVisibility(state, value: boolean) {
    state.showConsentSettings = value;
  },
  setCookieConsentSSRFetchStatus(state, value: boolean) {
    state.fetchSSRCookies = value;
  },
  setVendorsList(state, value: State['vendorsList'] | null) {
    state.vendorsList = value;
  },
};
