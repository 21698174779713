
import Vue from 'vue';
import { FragmentPicture } from '@core/types/fragment';
import buildPictureUrl from '@core/utils/buildPictureUrl';

type PictureSrcset = { xs: string; sm: string; md: string; lg: string; xl: string };

interface Props {
  id: string;
  cropperData: Record<string, any>;
  picture: Record<string, keyof FragmentPicture> | undefined;
  pictureSrcset: PictureSrcset | null;
  fallbackAltText: string;
  isLazy: boolean;
}

interface Data {
  sizesData: Record<string, { media: string }>;
}
interface Computed {
  src: string | null;
  srcset: PictureSrcset | Record<string, unknown>;
  altText: string | null;
}

interface Methods {
  getSrc: () => string | null;
  getSrcset: () => PictureSrcset | Record<string, unknown>;
  getNewSrc: () => string | null;
  getNewSrcset: () => string | null | any;
  getHdpiSize: (sizeData: any) => Record<string, number>;
}

const sizesData = {
  xl: {
    media: '(min-width: 1200px)',
  },
  lg: {
    media: '(min-width: 992px)',
  },
  md: {
    media: '(min-width: 768px)',
  },
  sm: {
    media: '(min-width: 576px)',
  },
  xs: {
    media: '(min-width: 240px)',
  },
};

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      sizesData,
    };
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    cropperData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    picture: {
      type: Object,
      required: false,
    },
    pictureSrcset: {
      type: Object,
      default: null,
      required: false,
    },
    fallbackAltText: {
      type: String,
      required: false,
      default: '',
    },
    isLazy: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    altText() {
      return this.picture?.caption || this.fallbackAltText || null;
    },
    src() {
      return this.id ? this.getNewSrc() : this.getSrc();
    },
    srcset() {
      return this.id ? this.getNewSrcset() : this.getSrcset();
    },
  },
  methods: {
    getSrc() {
      const { srcset } = this;
      const isEmpty = Object.keys(srcset).length === 0;
      if (isEmpty) {
        return '';
      }

      // Find smallest picture for default img src
      return (srcset['xs'] || srcset['md'] || srcset['sm'] || srcset['lg']) as string;
    },
    getSrcset() {
      const srcset: PictureSrcset | Record<string, unknown> = {};
      const sizeList = this.pictureSrcset;

      if (!sizeList) {
        return srcset;
      }

      // Create srcset for picture source tag
      for (const screenSize of Object.keys(sizeList)) {
        const size = sizeList[screenSize as keyof PictureSrcset];
        const picture = this.picture?.[size];

        if (picture) {
          srcset[screenSize as keyof PictureSrcset] = picture;
        }
      }

      return srcset as PictureSrcset;
    },
    getNewSrc() {
      const cropperData = { ...this.cropperData };
      const sizeData = cropperData['xs'] || cropperData['sm'] || cropperData['md'] || cropperData['lg'] || cropperData['xl'];

      // TODO! separate srcSet sizes from cropperData after content api v3
      if (sizeData?.customCrop) {
        // add size data and custom crop
        const customCrop = sizeData.customCrop;
        const sizeDataCopy = { ...sizeData };
        delete sizeDataCopy.customCrop;
        return buildPictureUrl({ id: this.id, cropperData: { ...sizeDataCopy, ...customCrop, rotate: cropperData.rotate, flip: cropperData.flip } });
      }
      return buildPictureUrl({ id: this.id, cropperData: { ...sizeData, ...cropperData.base, rotate: cropperData.rotate, flip: cropperData.flip } });
    },
    getHdpiSize(sizeData) {
      const size: Record<string, number> = {};

      if (typeof sizeData.w === 'number') {
        size.w = sizeData.w * 2;
      }
      if (typeof sizeData.h === 'number') {
        size.h = sizeData.h * 2;
      }
      return size;
    },
    getNewSrcset() {
      const cropperData = { ...this.cropperData };
      const srcset: Record<string, string | null> = {};

      // Find srcset sizes from cropperData: sm | md | lg
      for (const key of Object.keys(cropperData)) {
        if (this.sizesData[key]) {
          const sizeData = cropperData[key];
          // TODO! separate srcSet sizes from cropperData after content api v3
          if (sizeData?.customCrop) {
            const customCrop = sizeData.customCrop;
            const sizeDataCopy = { ...sizeData };
            delete sizeDataCopy.customCrop;

            const hdpiSize = this.getHdpiSize(sizeDataCopy);

            const defaultImage = buildPictureUrl({
              id: this.id,
              cropperData: { ...sizeDataCopy, ...customCrop, rotate: cropperData.rotate, flip: cropperData.flip },
            });
            // Image for HiDPI displays
            const hdpiImage = buildPictureUrl({
              id: this.id,
              cropperData: { ...sizeDataCopy, ...customCrop, rotate: cropperData.rotate, flip: cropperData.flip, ...hdpiSize },
            });
            srcset[key] = `${defaultImage}, ${hdpiImage} 2x`;
          } else {
            const hdpiSize = this.getHdpiSize(sizeData);
            const defaultImage = buildPictureUrl({
              id: this.id,
              cropperData: { ...sizeData, ...cropperData.base, rotate: cropperData.rotate, flip: cropperData.flip },
            });

            // Image for HiDPI displays
            const hdpiImage = buildPictureUrl({
              id: this.id,
              cropperData: { ...sizeData, ...cropperData.base, rotate: cropperData.rotate, flip: cropperData.flip, ...hdpiSize },
            });
            srcset[key] = `${defaultImage}, ${hdpiImage} 2x`;
          }
        }
      }
      return srcset;
    },
  },
});
